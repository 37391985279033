import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { subpageHero, aboutSection, twoBoxes } from './features.module.scss';

const AboutUs = () => {
    return (
        <>
            <SEO
                title='Um okkur - Tilkynna'
                description='Um okkur - Tilkynna'
            />
            <Layout isFrontpage>
                <div>
                    <section className={subpageHero}>
                        <div>
                            <div>
                                <h1>Um okkur</h1>
                                <h2>
                                    Tilkynna er fyrsta íslenska
                                    tilkynningakerfið sem er hannað með
                                    uppljóstrun í huga.
                                </h2>
                                <p>
                                    Tilkynna var þróað til að mæta þörf á
                                    íslenskum markaði vegna nýrra laga um vernd
                                    uppljóstrara. Tilkynna er í eigu Slidesome
                                    ehf.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className={aboutSection}>
                        <div>
                            <div className={twoBoxes}>
                                <div>
                                    <img src='/about/hkg.jpg' alt='' />
                                    <h3>Helga Kristín Gunnarsdóttir</h3>
                                    <h4>Framkvæmdastjóri</h4>
                                    <h5>
                                        <a
                                            className='special'
                                            href='mailto:helga@tilkynna.is'
                                        >
                                            helga@tilkynna.is
                                        </a>{' '}
                                        /{' '}
                                        <a
                                            className='special'
                                            target='_blank'
                                            href='https://www.linkedin.com/in/helga-krist%C3%ADn-g-602a3759/'
                                        >
                                            LinkedIn
                                        </a>
                                    </h5>
                                    <p>
                                        Helga Kristín er annar stofnenda
                                        Slidesome ehf. Áður starfaði hún hjá
                                        CCP, fyrst sem producer í leikjaþróun og
                                        síðar sem sr. producer og sr.
                                        development manager í vefteymi
                                        fyrirtækisins. Helga Kristín var
                                        trúnaðarmaður hjá CCP frá 2013 til 2018.
                                        Hún lauk námi í stjórnendamarkþjálfun
                                        frá Opna háskólanum í HR árið 2015.
                                    </p>
                                </div>
                                <div>
                                    <img src='/about/jta.jpg' alt='' />
                                    <h3>Jón Trausti Arason</h3>
                                    <h4>Tækni- og öryggisstjóri</h4>
                                    <h5>
                                        <a
                                            className='special'
                                            href='mailto:jon@tilkynna.is'
                                        >
                                            jon@tilkynna.is
                                        </a>{' '}
                                        /{' '}
                                        <a
                                            className='special'
                                            target='_blank'
                                            href='https://www.linkedin.com/in/jon-trausti/'
                                        >
                                            LinkedIn
                                        </a>
                                    </h5>
                                    <p>
                                        Jón Trausti er annar stofnenda Slidesome
                                        ehf. Hann er tölvunarfræðingur að mennt
                                        og starfaði áður hjá CCP sem sr.
                                        software engineer og technical lead í
                                        vefteymi CCP. Jón Trausti býr yfir
                                        margra ára reynslu í hugbúnaðarþróun og
                                        öruggri meðhöndlun gagna í skýinu.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    );
};

export default React.memo(AboutUs);
